import {
  Center,
  CircularProgress,
  Flex,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import {
  CrmIntegrationOrderBy,
  CrmIntegrationStatus,
  OrderQuery,
  useFindCrmIntegrationsQuery,
} from '@app/api/gql/generated-types';
import { ExportModal } from '@app/components/next/organisms/ExportModal';
import DownloadIcon from '@app/icons/export-icon.svg?react';
import { colors } from '@app/theme/colors';

export const Export = () => {
  const exportModal = useDisclosure();
  const [inProgress, setInProgress] = useState(false);

  // Preload Active integrations for child component
  useFindCrmIntegrationsQuery({
    variables: {
      filter: {
        status: CrmIntegrationStatus.ACTIVE,
      },
      order: {
        by: CrmIntegrationOrderBy.TYPE,
        direction: OrderQuery.ASC,
      },
    },
  });

  return (
    <>
      <Flex
        _hover={{
          // @ts-expect-error temporary mass-suppression
          color: !inProgress ?? 'primary.500 !',
        }}
        color="secondary.400"
        cursor="pointer"
        fontSize="14px"
        fontWeight={500}
        gap="6px"
        ml="15px"
        mt="2px"
        position="relative"
        onClick={!inProgress ? exportModal.onOpen : undefined}>
        {!inProgress && (
          <>
            <DownloadIcon />
            Export
          </>
        )}
        {inProgress && (
          <Tooltip
            hasArrow
            label="Export in progress, this might take a while…"
            placement="top">
            <Center>
              <CircularProgress
                color="gray"
                isIndeterminate={true}
                mr="6px"
                size="13px"
                thickness="10px"
                trackColor={colors.transparent}
              />
              Exporting...
            </Center>
          </Tooltip>
        )}
      </Flex>
      <ExportModal
        isOpen={exportModal.isOpen}
        setInProgress={setInProgress}
        onClose={exportModal.onClose}
      />
    </>
  );
};
