import type { UseDisclosureProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  Link,
  Text,
} from '@chakra-ui/react';
import { FormikHelpers, useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnChangeValue } from 'react-select';

import { ApolloError } from '@apollo/client';
import { isSafari } from 'react-device-detect';
import ContactSearch from '@app/components/ContactSearch';
import {
  PhoneNumberInput,
  preparingForSendingPhoneNumber,
} from '@app/components/PhoneNumberInput';
import Popup from '@app/components/Popup';
import SelectField, { SelectOption } from '@app/components/SelectField';
import ToastNotify from '@app/components/ToastNotifier';
import { selectOptions } from '@app/pages/Messenger/utils/selectOptionsData';
import { catchErrorLog } from '@app/utils/logger';
import { normalizeNameString } from '@app/utils/string';
import { isSubmitDisabled } from '@app/utils/validation';

import {
  ConversationStatus,
  FindContactsDocument,
  FindConversationsDocument,
  GetContactsCountersDocument,
  useCreateContactMutation,
  useCreateConversationMutation,
  useGetConversationLazyQuery,
} from '@app/api/gql/generated-types';
import { useTrialMaxContactsGuard } from '@app/hooks/useTrialMaxContactsGuard';
import { ExceptionCode } from '@app/types/ApolloErrors';
import {
  getGraphQLErrorCode,
  getGraphQLErrorException,
} from '@app/utils/getGraphQLErrorCode';
import { contactCreateSchema } from './schema';
import { CreateContactValues } from './types';

const SELECTED_NEW_CONTACT = selectOptions[0];

const notifyMessages = {
  success: 'New contact created',
  error: 'Invalid phone number',
  info: 'Contact with this number already exists',
};

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  status: 'all',
};

export const CreateConversationDialog: FC<UseDisclosureProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const [selectContacts, setSelectContacts] =
    useState<SelectOption>(SELECTED_NEW_CONTACT);

  const { isValidating } = useTrialMaxContactsGuard();

  const [queryGetConversation, { loading: isLoadingGetConversation }] =
    useGetConversationLazyQuery();

  const [
    mutateCreateContact,
    { loading: isLoadingCreateContact, error: errorCreateContact },
  ] = useCreateContactMutation({
    refetchQueries: [FindContactsDocument, GetContactsCountersDocument],
  });

  const [mutateCreateConversation, { loading: isLoadingCreateConversation }] =
    useCreateConversationMutation({
      refetchQueries: [FindConversationsDocument],
    });

  const isLoading = isLoadingCreateContact || isLoadingCreateConversation;

  const selectedNewContact = selectContacts === SELECTED_NEW_CONTACT;

  const [conversationId, setConversationId] = useState<string>();

  const getConversationStatus = async (id: string) => {
    const { data: { getConversation: conversation } = {} } =
      await queryGetConversation({ variables: { id } });

    if (!conversation) {
      return 'all';
    }

    let status = 'all';
    if (!conversation.isRead && conversation.lastMessage) {
      status = 'unread';
    }

    if (conversation.isUnsubscribed) {
      status = 'opted-out';
    }

    if (conversation.status === ConversationStatus.DELETED) {
      status = 'deleted';
    }

    return status;
  };

  const onSubmit = async (
    values: CreateContactValues,
    { resetForm, setFieldValue }: FormikHelpers<CreateContactValues>,
  ) => {
    try {
      const { data: { createConversation } = {} } =
        await mutateCreateConversation({
          variables: {
            input: {
              contactId: values.id || conversationId,
            },
          },
        });

      if (selectedNewContact) {
        ToastNotify({
          status: 'success',
          position: 'top-right',
          title: notifyMessages.success,
        });
      }

      const status = await getConversationStatus(values.id || conversationId);
      await setFieldValue('status', status);
      if (!status) {
        return;
      }

      navigate(`/messenger/inbox/${status}/${createConversation.id}/messages`);

      resetForm();
      onClose();
    } catch (err) {
      catchErrorLog(err, 'CreateConversationDialog/handleSubmit');
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: contactCreateSchema(!!selectedNewContact),
    initialValues,
    // @ts-expect-error temporary mass-suppression
    onSubmit,
  });

  const selectContactsHandler = (
    selectedOption: OnChangeValue<SelectOption, false>,
  ) => {
    setSelectContacts(selectedOption);
    formik.resetForm();
  };

  const isDisableStartConversation = isSubmitDisabled(formik, false, []);

  const handleFormReset = () => {
    formik.resetForm();
    onClose();
    setSelectContacts(SELECTED_NEW_CONTACT);
  };

  const createNewContact = async (): Promise<boolean> => {
    try {
      await mutateCreateContact({
        variables: {
          input: {
            phone: preparingForSendingPhoneNumber(formik.values.phoneNumber),
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
          },
        },
        onCompleted(data) {
          setConversationId(data?.createContact.id);
        },
      });

      return true;
    } catch (err) {
      const errContext = 'CreateConversationDialog/createNewContact';
      if (!(err instanceof ApolloError)) {
        catchErrorLog(err, errContext);
        return;
      }

      const { contactId = '' } = getGraphQLErrorException<{
        contactId?: string;
      }>(err);
      if (!!contactId) {
        const status = await getConversationStatus(contactId);
        await formik.setFieldValue('status', status);
      }

      const errCode = getGraphQLErrorCode(err);
      if (errCode === ExceptionCode.CONFLICT && !isSafari) {
        formik.setFieldError('contact', notifyMessages.info);
        ToastNotify({
          status: 'info',
          position: 'top-right',
          title: err?.graphQLErrors[0].message || notifyMessages.error,
        });
      } else if (errCode === ExceptionCode.GRAPHQL_VALIDATION_FAILED) {
        formik.setFieldError('contact', notifyMessages.error);
        ToastNotify({
          status: 'error',
          position: 'top-right',
          title: notifyMessages.error,
        });
      } else if (
        isSafari &&
        errCode !== ExceptionCode.GRAPHQL_VALIDATION_FAILED
      ) {
        formik.setFieldError('contact', notifyMessages.info);
        ToastNotify({
          status: 'info',
          position: 'top-right',
          title: notifyMessages.info,
        });
      } else {
        catchErrorLog(err, errContext);
      }

      return false;
    }
  };

  const startConversationHandler = async () => {
    // Wake delay to display if an error exists when creating a duplicate contact
    const submit = () => {
      setTimeout(() => {
        formik.handleSubmit();
      }, 1);
    };

    if (selectedNewContact) {
      if (await createNewContact()) {
        submit();
      }
    } else {
      submit();
    }
  };

  const handleContactSearch = async ({ label, value }: SelectOption) => {
    // @ts-expect-error temporary mass-suppression
    await formik.setValues({ firstName: label, id: value });
  };

  const contactSearchValue =
    // @ts-expect-error temporary mass-suppression
    !!formik.values.firstName && !!formik.values.id
      ? {
          label: formik.values.firstName,
          // @ts-expect-error temporary mass-suppression
          value: formik.values.id,
        }
      : undefined;

  const goToPage = () => {
    handleFormReset();
    const existingContactId = getGraphQLErrorException(errorCreateContact)
      ?.contactId as string;
    navigate(
      `/messenger/inbox/${formik.values.status}/${existingContactId}/messages`,
    );
  };

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="420px"
      title="Create a new conversation"
      onClose={handleFormReset}>
      <Box marginTop="35px" padding="0px 40px">
        <Box mb="20px">
          <SelectField
            isSearchable={false}
            // @ts-expect-error Select mismatch between value and onChange and options
            options={selectOptions}
            placeholder={selectContacts?.label}
            value={selectContacts?.label}
            // @ts-expect-error Select mismatch between value and onChange and options
            onChange={selectContactsHandler}
          />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          {selectedNewContact ? (
            <Box>
              <InputGroup mb="20px">
                <HStack justifyContent="space-between" spacing="20px" w="full">
                  <FormControl
                    isInvalid={
                      !!formik.touched.firstName && !!formik.errors?.firstName
                    }>
                    <Input
                      id="firstName"
                      isInvalid={
                        !!formik.touched.firstName && !!formik.errors?.firstName
                      }
                      name="firstName"
                      placeholder="First name"
                      type="text"
                      value={formik.values.firstName}
                      variant="primary"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        e.target.value = normalizeNameString(e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik.errors?.firstName}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      !!formik.touched.lastName && !!formik.errors?.lastName
                    }>
                    <Input
                      id="lastName"
                      isInvalid={
                        !!formik.touched.lastName && !!formik.errors?.lastName
                      }
                      name="lastName"
                      placeholder="Last name"
                      type="text"
                      value={formik.values.lastName}
                      variant="primary"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        e.target.value = normalizeNameString(e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                    <FormErrorMessage>
                      {formik.errors?.lastName}
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              </InputGroup>
              <FormControl
                isInvalid={
                  !!formik.touched.phoneNumber && !!formik.errors?.phoneNumber
                }
                mb="10px">
                <PhoneNumberInput
                  hasError={
                    !!formik.touched.phoneNumber && !!formik.errors.phoneNumber
                  }
                  value={formik.values.phoneNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>
                  {formik.errors?.phoneNumber}
                </FormErrorMessage>
              </FormControl>
            </Box>
          ) : (
            <Box mb="10px">
              <ContactSearch
                value={contactSearchValue}
                onChange={handleContactSearch}
              />
            </Box>
          )}
        </form>
        {
          // @ts-expect-error temporary mass-suppression
          !!formik.errors.contact &&
            // @ts-expect-error temporary mass-suppression
            formik.errors.contact !== notifyMessages.error && (
              <Flex
                bg="zircon"
                borderRadius="10px"
                direction="column"
                mb="10px"
                padding="15px">
                <Text variant="heading-fifth">
                  Contact with this number already exists.{' '}
                  <Button
                    as={Link}
                    variant="linkSecondary"
                    whiteSpace="normal"
                    onClick={goToPage}>
                    Open conversation with this number
                  </Button>
                </Text>
              </Flex>
            )
        }
      </Box>
      <Box mt="10px" padding="0 40px 40px">
        <Button
          isDisabled={isDisableStartConversation}
          isLoading={isLoading || isValidating || isLoadingGetConversation}
          type="submit"
          variant="primary"
          width="100%"
          onClick={startConversationHandler}>
          &#43; Start a conversation
        </Button>
      </Box>
    </Popup>
  );
};
