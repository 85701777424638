import { Text } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { FC, useMemo, useState } from 'react';
import { components, OptionProps, PlaceholderProps } from 'react-select';
import SelectField from '@app/components/SelectField';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  ContactStatus,
  useFindContactsPhonesQuery,
} from '@app/api/gql/generated-types';
import { getContactNames } from '@app/utils/contact';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import { ContactSearchOption } from './types';

interface ContactSearchProps {
  onChange: (value: ContactSearchOption) => void;
  value: ContactSearchOption;
  error?: string;
  isDisabled?: boolean;
}

const Placeholder = <T extends ContactSearchOption, M extends boolean = false>(
  props: PlaceholderProps<T, M>,
) => {
  const value = props.selectProps?.value ?? 'Select contact';

  return (
    <components.Placeholder {...props}>
      {/* @ts-expect-error temporary mass-suppression */}
      <>{props?.selectProps?.menuIsOpen ? 'Type the name' : value}</>
    </components.Placeholder>
  );
};

const Option = <T extends ContactSearchOption, M extends boolean = false>(
  props: OptionProps<T, M>,
) => {
  return (
    <components.Option {...props}>
      <Text variant="heading-fifth">
        {props?.label}
        <Text as="span" color="secondary.400" ml="10px">
          <PhoneNumberFormat value={props?.data?.phone} />
        </Text>
      </Text>
    </components.Option>
  );
};

const ContactSearch: FC<ContactSearchProps> = ({
  onChange,
  value,
  error,
  isDisabled,
}) => {
  const [searchContact, setSearchContact] = useState<string>('');

  const {
    data: { findContacts: contactsData } = {},
    loading: isLoading,
    fetchMore,
  } = useFindContactsPhonesQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        isBlocked: false,
        ...(searchContact.length > 3 && { fullSearch: searchContact }),
        status: ContactStatus.ACTIVE,
      },
    },
  });

  const items = useMemo(
    () =>
      contactsData?.items?.map((contact) => ({
        label: getContactNames(contact)?.fullName,
        phone: contact.phone ?? '',
        value: contact.id,
      })) ?? [],
    [contactsData?.items],
  );

  const handleNextPage = () =>
    contactsData?.hasNext && !(contactsData?.items?.length && isLoading)
      ? fetchMore(createPaginationVariables(contactsData))
      : {};

  const searchHandler = debounce((query: string) => {
    setSearchContact(query);
  }, 50);

  return (
    <SelectField
      isClearable
      isSearchable
      components={{ Option, Placeholder }}
      error={error}
      inputValue={searchContact}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={items}
      placeholder={value ? value?.label : 'Select contact'}
      // @ts-expect-error Select mismatch between value and onChange and options
      value={value?.label}
      // @ts-expect-error Select mismatch between value and onChange and options
      onChange={onChange}
      onInputChange={searchHandler}
      onMenuScrollToBottom={debounce(handleNextPage, 100)}
    />
  );
};

export default ContactSearch;
