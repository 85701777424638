import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import take from 'lodash/take';
import React, { ReactNode } from 'react';
// eslint-disable-next-line import/default
import Select, {
  ActionMeta,
  components,
  GroupBase,
  OptionProps,
  Props,
  ValueContainerProps,
} from 'react-select';

import { colors } from '@app/theme/colors';
import { customStyles } from './styles';

interface SelectProps<T, M extends boolean, G extends GroupBase<T>>
  extends Props<T, M, G> {
  isReadOnly?: boolean;
  error?: string;
  color?: string;
  itemsLength?: number;
}

const DEFAULT_ITEMS_LENGTH = 3;

const Option = <
  T = {
    label: string;
    value: string;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: OptionProps<T, M, G>,
) => {
  return (
    <components.Option {...props}>
      <Box
        _hover={{
          backgroundColor: colors.primary[200],
          color: 'primary.600',
        }}
        borderRadius="20px">
        <Checkbox
          isChecked={props?.isSelected}
          pointerEvents="none"
          variant="multiList">
          {props?.label}
        </Checkbox>
      </Box>
    </components.Option>
  );
};
const ValueContainer = <
  T = {
    label: string;
    value: DocumentReference;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>({
  children,
  ...rest
}: ValueContainerProps<T, M, G> & { children: ReactNode[] }) => {
  const itemsLength = rest.selectProps.itemsLength ?? DEFAULT_ITEMS_LENGTH;
  const selectedCount = rest.getValue().length;
  const conditional = selectedCount <= itemsLength;

  let items: React.ReactNode = [];

  if (!conditional) {
    // @ts-expect-error we expect children to be an array, and there is a TS type mismatch between number and ArrayLike<string>
    items = [take(children[0], itemsLength), children[1]];
  }

  return (
    <components.ValueContainer {...rest}>
      <>
        {conditional ? children : items}
        {!conditional && (
          <Flex
            alignItems="center"
            bg="main.100"
            borderRadius="16px"
            color="white"
            h="28px"
            justifyContent="center"
            justifySelf="flex-end"
            p="0 10px">
            +{selectedCount - itemsLength}
          </Flex>
        )}
        {selectedCount > 0 && selectedCount < itemsLength && (
          <Flex>
            {/* @ts-expect-error temporary mass-suppression */}
            <components.Placeholder {...rest}>
              {rest?.selectProps?.placeholder}
            </components.Placeholder>
          </Flex>
        )}
      </>
    </components.ValueContainer>
  );
};

interface MultiSelectFieldProps<T, M extends boolean, G extends GroupBase<T>>
  extends Omit<SelectProps<T, M, G>, 'onChange'> {
  maxWidth?: string;
  onChange?: (newValue: T[], actionMeta: ActionMeta<T>) => void;
}
const MultiSelectField = <
  T = {
    label: string;
    value: string | boolean | number;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>({
  isSearchable,
  error,
  isReadOnly,
  maxWidth,
  onChange,
  ...props
}: MultiSelectFieldProps<T, M, G>) => (
  <Select
    // @ts-expect-error "react-select" mismatch custom components
    components={{ Option, ValueContainer }}
    isDisabled={isReadOnly}
    isSearchable={!!isSearchable}
    // @ts-expect-error styles error react-select
    styles={customStyles(error, maxWidth || '26%')}
    onChange={(newValue, actionMeta) =>
      onChange(newValue as T[], actionMeta as ActionMeta<T>)
    }
    {...props}
  />
);

export default MultiSelectField;
