import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import {
  Link as RouteLink,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { PageTitle } from '@app/components/PageTitle';
import SelectField from '@app/components/SelectField';
import {
  statusOptions,
  templateOptions,
} from '@app/pages/PopupConstructor/content/selectOptions';
import { AudienceSelect } from '@app/pages/PopupConstructor/components/AudienceSelect';
import { PopupConstructor } from '@app/pages/PopupConstructor/types';
import { isSubmitDisabled } from '@app/utils/validation';
import { Preview } from '@app/pages/PopupConstructor/components/Preview';
import {
  disabledDeps,
  hasButton,
  hasVideo,
  initialValues,
  prepareAudience,
  prepareDataForForm,
} from '@app/pages/PopupConstructor/utils';
import { ConfirmModal } from '@app/widgets/ConfirmModal';
import ROUTES from '@app/utils/routes';
import DateFormat from '@app/components/DateFormat';
import { Wysiwyg } from '@app/widgets/Wysiwyg';
import {
  useCreateMarketingPopupMutation,
  CreateMarketingPopupInput,
  MarketingPopupStatus,
  MarketingPopupTemplateType,
  useGetMarketingPopupQuery,
  useUpdateMarketingPopupMutation,
  FindMarketingPopupsDocument,
} from '@app/api/gql/generated-types';
import { catchErrorLog } from '@app/utils/logger';
import { ImageInput } from '@app/pages/PopupConstructor/components/ImageInput';
import Skeleton from '@app/components/Skeleton';

import { DateFields } from '../components/DateFields';

const PopupCreate: FC = () => {
  const cancelPopup = useDisclosure();
  const savePopup = useDisclosure();
  const navigate = useNavigate();
  const [imagePath, setImagePath] = useState<string>('');

  const { id } = useParams<{ id: string }>();
  const isEdit = Boolean(useMatch(`${ROUTES.popupConstructor}/edit/:id`));

  const [createMarketingPopupMutation, { loading: popupCreateLoading }] =
    useCreateMarketingPopupMutation({
      refetchQueries: [
        {
          query: FindMarketingPopupsDocument,
          fetchPolicy: 'no-cache',
        },
      ],
      context: {
        notify: {
          success: () => 'Popup successfully created',
        },
      },
    });

  const [updateMarketingPopupMutation, { loading: popupUpdateLoading }] =
    useUpdateMarketingPopupMutation({
      refetchQueries: [
        {
          query: FindMarketingPopupsDocument,
          fetchPolicy: 'no-cache',
        },
      ],
      context: {
        notify: {
          success: () => 'Popup successfully updated',
        },
      },
    });

  const { data: popupData, loading: popupLoading } = useGetMarketingPopupQuery({
    variables: {
      id,
    },
    skip: !isEdit,
  });

  const popupDataValues = popupData?.getMarketingPopup;

  const createPopup = async (input: CreateMarketingPopupInput) => {
    try {
      await createMarketingPopupMutation({
        variables: {
          input,
        },
        context: {
          notify: {
            success: () => 'Successfully saved',
          },
        },
      });

      savePopup.onClose();
      navigate(ROUTES.popupConstructor);
    } catch (error) {
      catchErrorLog(error, 'PopupCreate/createPopup');
    }
  };

  const updatePopup = async (input: CreateMarketingPopupInput) => {
    try {
      await updateMarketingPopupMutation({
        variables: {
          input,
          id,
        },
      });
      savePopup.onClose();
      navigate(ROUTES.popupConstructor);
    } catch (error) {
      catchErrorLog(error, 'PopupCreate/updatePopup');
    }
  };

  const handlePopupSubmit = async (values: Partial<PopupConstructor>) => {
    let imagePathValue = imagePath;

    if (
      popupDataValues?.template?.imageSrc &&
      !imagePath &&
      popupDataValues.template.type ===
        (values.template.type.value as MarketingPopupTemplateType)
    ) {
      imagePathValue = undefined;
    }

    const input: CreateMarketingPopupInput = {
      status: values.status.value as MarketingPopupStatus,
      audiences: prepareAudience(values.audiences),
      title: values.title,
      description: values.description,
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString(),
      template: {
        type: values.template.type.value as MarketingPopupTemplateType,
        videoSrc: values.template.videoSrc,
        imagePath: imagePathValue,
        buttonText: values.template.buttonText,
        buttonLink: values.template.buttonLink,
      },
    };

    if (isEdit) {
      await updatePopup(input);
    } else {
      await createPopup(input);
    }
  };

  if (popupLoading) {
    return <Skeleton />;
  }

  const pageTitle = isEdit ? popupDataValues.title : 'New Popup';

  return (
    <Flex height="100%" overflow="auto" w="100%">
      <PageTitle title={pageTitle} />
      <Formik
        enableReinitialize
        initialValues={
          isEdit ? prepareDataForForm(popupDataValues) : initialValues
        }
        onSubmit={savePopup.onOpen}>
        {({
          values,
          setFieldValue,
          setValues,
          handleSubmit,
          isValid,
          dirty,
          handleChange,
        }) => {
          const isEditDisabled =
            isEdit &&
            (values.status?.value as MarketingPopupStatus) ===
              MarketingPopupStatus.ACTIVE;

          return (
            <Flex height="100%" overflow="auto" w="100%">
              <Box ml="120px" mr="150px" w="485px">
                <Form onSubmit={handleSubmit}>
                  <Text
                    data-cy="new-popup-text"
                    mb="30px"
                    mt="45px"
                    variant="page-title">
                    {pageTitle}
                  </Text>
                  <Stack mr="20px">
                    <Flex alignItems="center" justify="space-between" w="100%">
                      <Text
                        color="main.400"
                        fontSize="14px"
                        fontWeight="400"
                        my="20px">
                        Popup Status
                      </Text>
                      <Box width="345px">
                        <SelectField
                          name="status"
                          options={statusOptions}
                          placeholder="Select status"
                          value={values.status}
                          onChange={(v) => setFieldValue('status', v)}
                        />
                      </Box>
                    </Flex>
                    <Flex alignItems="center" justify="space-between" w="100%">
                      <Text
                        color="main.400"
                        fontSize="14px"
                        fontWeight="400"
                        my="20px">
                        Audience
                      </Text>
                      <AudienceSelect isDisabled={isEditDisabled} />
                    </Flex>
                    <Flex alignItems="center" justify="space-between" w="100%">
                      <Text
                        color="main.400"
                        fontSize="14px"
                        fontWeight="400"
                        my="20px">
                        Template
                      </Text>
                      <Box width="345px">
                        <SelectField
                          isDisabled={isEditDisabled}
                          name="template.type"
                          options={templateOptions}
                          placeholder="Select template"
                          value={values.template.type}
                          onChange={(value) => {
                            void setValues({
                              ...values,
                              title: '',
                              description: '',
                              template: {
                                ...initialValues.template,
                                // @ts-expect-error temporary mass-suppression
                                type: value,
                              },
                            });
                          }}
                        />
                      </Box>
                    </Flex>
                    <Flex alignItems="center" justify="space-between" w="100%">
                      <Text
                        color="main.400"
                        fontSize="14px"
                        fontWeight="400"
                        my="20px">
                        Title
                      </Text>
                      <Input
                        data-cy="popupTitle"
                        isDisabled={
                          isEditDisabled || !values.template?.type?.value
                        }
                        name="title"
                        placeholder="Title"
                        type="text"
                        value={values.title}
                        variant="primary"
                        width="345px"
                        onChange={handleChange}
                      />
                    </Flex>
                    <Flex alignItems="center" justify="space-between" w="100%">
                      <Text
                        color="main.400"
                        fontSize="14px"
                        fontWeight="400"
                        my="20px">
                        Description
                      </Text>
                      <Wysiwyg
                        key={values.template?.type?.value}
                        disabled={
                          isEditDisabled || !values.template?.type?.value
                        }
                        initialContent={values.description}
                        name="description"
                      />
                    </Flex>

                    {hasVideo(values.template.type) && (
                      <Flex
                        alignItems="center"
                        justify="space-between"
                        w="100%">
                        <Text
                          color="main.400"
                          fontSize="14px"
                          fontWeight="400"
                          my="20px">
                          Video
                        </Text>
                        <Input
                          data-cy="popupVideo"
                          isDisabled={isEditDisabled}
                          name="template.videoSrc"
                          placeholder="Video link"
                          type="text"
                          value={values.template?.videoSrc}
                          variant="primary"
                          width="345px"
                          onChange={handleChange}
                        />
                      </Flex>
                    )}
                    <Flex alignItems="center" justify="space-between" w="100%">
                      <Text
                        color="main.400"
                        fontSize="14px"
                        fontWeight="400"
                        my="20px">
                        Image
                      </Text>
                      <ImageInput
                        key={values.template?.type?.value}
                        disabled={
                          isEditDisabled || !values.template?.type?.value
                        }
                        onUpload={setImagePath}
                      />
                    </Flex>
                    {hasButton(values.template.type) && (
                      <Flex
                        alignItems="center"
                        justify="space-between"
                        w="100%">
                        <Text
                          color="main.400"
                          fontSize="14px"
                          fontWeight="400"
                          my="20px">
                          Button Text
                        </Text>
                        <Input
                          data-cy="buttonText"
                          isDisabled={isEditDisabled}
                          maxLength={30}
                          name="template.buttonText"
                          placeholder="There is a limit of 30 symbols"
                          type="text"
                          value={values.template.buttonText}
                          variant="primary"
                          width="345px"
                          onChange={handleChange}
                        />
                      </Flex>
                    )}
                    {hasButton(values.template.type) && (
                      <Flex
                        alignItems="center"
                        justify="space-between"
                        w="100%">
                        <Text
                          color="main.400"
                          fontSize="14px"
                          fontWeight="400"
                          my="20px">
                          Button Link
                        </Text>
                        <Input
                          data-cy="buttonLink"
                          isDisabled={isEditDisabled}
                          name="template.buttonLink"
                          placeholder="https://"
                          type="text"
                          value={values.template.buttonLink}
                          variant="primary"
                          width="345px"
                          onChange={handleChange}
                        />
                      </Flex>
                    )}
                    <DateFields isEditDisabled={isEditDisabled} />
                    <Flex
                      alignItems="start"
                      justify="center"
                      pb="70px"
                      w="100%">
                      <Button
                        mr="10px"
                        variant="outlined"
                        onClick={cancelPopup.onOpen}>
                        Cancel
                      </Button>
                      <Button
                        isDisabled={isSubmitDisabled(
                          { isValid, dirty },
                          false,
                          // @ts-expect-error temporary mass-suppression
                          disabledDeps(values),
                        )}
                        ml="10px"
                        type="submit"
                        variant="primary"
                        w="120px"
                        onClick={savePopup.onOpen}>
                        Save
                      </Button>
                    </Flex>
                  </Stack>
                </Form>
              </Box>
              <Preview />

              <ConfirmModal
                buttons={
                  <Flex justify="space-between" mt="30px" w="100%">
                    <Button variant="outlined" onClick={cancelPopup.onClose}>
                      Cancel
                    </Button>
                    <Button
                      as={RouteLink}
                      to={`${ROUTES.popupConstructor}`}
                      variant="primary">
                      Confirm
                    </Button>
                  </Flex>
                }
                description={
                  'Are you sure you want to leave now?\n Changes will not be saved.'
                }
                isOpen={cancelPopup.isOpen}
                title="Confirmation"
                onClose={cancelPopup.onClose}
              />

              <ConfirmModal
                buttons={
                  <Flex justify="space-between" mt="30px" w="100%">
                    <Button variant="outlined" onClick={savePopup.onClose}>
                      Cancel
                    </Button>
                    <Button
                      isLoading={popupCreateLoading || popupUpdateLoading}
                      variant="primary"
                      // @ts-expect-error temporary mass-suppression
                      onClick={() => handlePopupSubmit(values)}>
                      Confirm
                    </Button>
                  </Flex>
                }
                description={
                  (values.status?.value as MarketingPopupStatus) ===
                  MarketingPopupStatus.ACTIVE ? (
                    <>
                      This in app notification will be published <br />
                      to production. Start date:{' '}
                      <Text as="span" color="osloGray">
                        <DateFormat
                          date={values.startDate}
                          format="LLL dd h:mm a"
                          timeZone="utc"
                        />
                      </Text>
                      . <br />
                      Confirm publishing?
                    </>
                  ) : (
                    'Popup status is Inactive. Are you sure you\n want to continue?'
                  )
                }
                isOpen={savePopup.isOpen}
                title="Confirmation"
                onClose={savePopup.onClose}
              />
            </Flex>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default PopupCreate;
