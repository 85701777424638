import { useCallback, useMemo } from 'react';

import {
  FeatureType,
  OwnPhoneType,
  Product,
  Scope,
  useGetOwnPhoneCountersQuery,
} from '@app/api/gql/generated-types';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

export const useMaxDialerAllowedPhones = () => {
  const { features } = useCurrentAccountData();
  const maxAllowedPhones = useMemo(
    () => ({
      [OwnPhoneType.LOCAL]:
        features.find(
          (f) =>
            f.type === FeatureType.LOCAL_PHONE && f.product === Product.DIALER,
        )?.limit || 0,
      [OwnPhoneType.TOLL_FREE]:
        features.find(
          (f) =>
            f.type === FeatureType.TOLL_FREE_PHONE &&
            f.product === Product.DIALER,
        )?.limit || 0,
    }),
    [features],
  );

  const { data: { getOwnPhoneCounters: phones } = {} } =
    useGetOwnPhoneCountersQuery({ variables: { scope: Scope.DIALER } });
  const local = phones?.local ?? 0;
  const tollFree = phones?.tollFree ?? 0;

  const phoneType = useMemo(
    () => ({
      [OwnPhoneType.LOCAL]: local,
      [OwnPhoneType.TOLL_FREE]: tollFree,
    }),
    [local, tollFree],
  );

  const canBuyLocalNumber = local < maxAllowedPhones[OwnPhoneType.LOCAL];

  const canBuyTollFreeNumber =
    tollFree < maxAllowedPhones[OwnPhoneType.TOLL_FREE];

  const all = useMemo(
    () => canBuyLocalNumber || canBuyTollFreeNumber,
    [canBuyLocalNumber, canBuyTollFreeNumber],
  );

  const byType = useCallback(
    (type: OwnPhoneType) =>
      maxAllowedPhones[type] && phoneType?.[type] >= maxAllowedPhones[type],
    [phoneType, maxAllowedPhones],
  );

  return {
    all,
    byType,
    canBuyLocalNumber,
    canBuyTollFreeNumber,
  };
};
