import sbjs from 'sourcebuster';

const initSourceBuster = () => {
  sbjs.init({
    domain: {
      host: 'smartercontact.com',
      isolate: false,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  (window as any).__sbjs = sbjs;
};

export default initSourceBuster;
