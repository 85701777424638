import { BaseText, Descendant } from 'slate';
import { jsx } from 'slate-hyperscript';

import { CustomText } from '../config/custom-types';

// TODO(@drugoi): This is a hack that should be removed once we have a better way to type deserialize function
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deserialize = (
  el: HTMLElement | ChildNode,
  markAttributes = {},
) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case 'STRONG':
      (nodeAttributes as CustomText).bold = true;
      break;
    case 'EM':
      (nodeAttributes as CustomText).italic = true;
      break;
    case 'U':
      (nodeAttributes as CustomText).underline = true;
      break;
    case 'S':
      (nodeAttributes as CustomText).strikethrough = true;
      break;
  }

  const children: Descendant | '\n'[] | BaseText = Array.from(el.childNodes)
    // @ts-expect-error temporary mass-suppression
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    // @ts-expect-error temporary mass-suppression
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'P':
      return jsx(
        'element',
        {
          type: 'paragraph',
          align: (el as HTMLElement).style.textAlign || 'left',
        },
        children,
      );
    default:
      return children;
  }
};
