import { Box, Button, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';

import {
  OwnPhone,
  OwnPhoneType,
  useFindAvailablePhonesQuery,
  usePurchaseOwnPhoneMutation,
} from '@app/api/gql/generated-types';
import PhoneNumberSelect from '@app/components/PhoneNumberSelect';
import { catchErrorLog } from '@app/utils/logger';

export const SelectTrialNumber = () => {
  const { data: { findAvailablePhones: data } = {}, loading: isLoading } =
    useFindAvailablePhonesQuery({
      variables: {
        filter: {
          type: OwnPhoneType.LOCAL,
        },
      },
    });
  const [mutate, { loading }] = usePurchaseOwnPhoneMutation();

  const [value, setValue] = useState<OwnPhone>();

  const onSubmit = async () => {
    try {
      await mutate({
        variables: {
          // @ts-expect-error temporary mass-suppression
          input: {
            phone: value.id,
            type: value.type,
          },
        },
      });
    } catch (error) {
      catchErrorLog(error, 'SelectTrialNumber/onSubmit');
    }
  };

  return (
    <Flex alignItems="center">
      <Box mr="20px" w="300px">
        <PhoneNumberSelect
          isDisabled={isLoading}
          isLoading={isLoading}
          // @ts-expect-error Select mismatch between value and onChange and options
          options={data}
          value={value}
          // @ts-expect-error Select mismatch between value and onChange and options
          onChange={setValue}
        />
      </Box>
      <Button
        isDisabled={!value || loading}
        isLoading={loading}
        variant="primary"
        w="142px"
        onClick={onSubmit}>
        Choose number
      </Button>
    </Flex>
  );
};
